<template>
  <PSignupForm @submit="handleSubmit">
    <template slot="title">
      {{ $t('パスワードリセット') }}
    </template>
    <template slot="contents">
      <p
        class="p-authSection__lead content"
        v-t="{
          path:
            'パスワードをリセットし、検証コードを送信します。\n{siteName}に登録しているメールアドレスを入力してください。',
          args: {
            siteName: $t(`${portalId}.おみせのトラスト`),
          },
        }"
      />
      <div class="p-authInputs">
        <PAuthInput
          class="p-authInputs__input"
          type="email"
          v-model.trim="$v.username.$model"
          :placeholder="$t('メールアドレス')"
          autocomplete="email"
          required
          :is-error="$v.username.$error"
        />
      </div>
      <p
        class="p-authSection__annotation"
        v-t="
          'メールが受信できない場合は「@trust-shop.hitachi.co.jp」のドメインを受信する設定にご変更ください。'
        "
      />
      <button
        type="submit"
        class="p-authSection__button c-button"
        v-t="'検証コードを送信'"
        :disabled="$v.$invalid"
      />
    </template>
  </PSignupForm>
</template>

<script>
import PAuthInput from '@/components/auth/PAuthInput.vue'
import PSignupForm from '@/components/auth/PSignupForm.vue'
import { required, email } from 'vuelidate/lib/validators'

export default {
  name: 'PasswordResetPresentation',
  components: {
    PAuthInput,
    PSignupForm,
  },
  data() {
    return {
      username: '',
    }
  },
  validations: {
    username: {
      required,
      email,
    },
  },
  methods: {
    handleSubmit() {
      this.$emit('forgotPassword', {
        username: this.username,
      })
    },
  },
}
</script>
