var render = function render(){var _vm=this,_c=_vm._self._c;return _c('PSignupForm',{on:{"submit":_vm.handleSubmit}},[_c('template',{slot:"title"},[_vm._v(" "+_vm._s(_vm.$t('パスワードリセット'))+" ")]),_c('template',{slot:"contents"},[_c('p',{directives:[{name:"t",rawName:"v-t",value:({
        path:
          'パスワードをリセットし、検証コードを送信します。\n{siteName}に登録しているメールアドレスを入力してください。',
        args: {
          siteName: _vm.$t(`${_vm.portalId}.おみせのトラスト`),
        },
      }),expression:"{\n        path:\n          'パスワードをリセットし、検証コードを送信します。\\n{siteName}に登録しているメールアドレスを入力してください。',\n        args: {\n          siteName: $t(`${portalId}.おみせのトラスト`),\n        },\n      }"}],staticClass:"p-authSection__lead content"}),_c('div',{staticClass:"p-authInputs"},[_c('PAuthInput',{staticClass:"p-authInputs__input",attrs:{"type":"email","placeholder":_vm.$t('メールアドレス'),"autocomplete":"email","required":"","is-error":_vm.$v.username.$error},model:{value:(_vm.$v.username.$model),callback:function ($$v) {_vm.$set(_vm.$v.username, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.username.$model"}})],1),_c('p',{directives:[{name:"t",rawName:"v-t",value:(
        'メールが受信できない場合は「@trust-shop.hitachi.co.jp」のドメインを受信する設定にご変更ください。'
      ),expression:"\n        'メールが受信できない場合は「@trust-shop.hitachi.co.jp」のドメインを受信する設定にご変更ください。'\n      "}],staticClass:"p-authSection__annotation"}),_c('button',{directives:[{name:"t",rawName:"v-t",value:('検証コードを送信'),expression:"'検証コードを送信'"}],staticClass:"p-authSection__button c-button",attrs:{"type":"submit","disabled":_vm.$v.$invalid}})])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }