<template>
  <PasswordResetPresentation
    :error-code="errorCode"
    @forgotPassword="handleForgotPassword"
  />
</template>

<script>
import PasswordResetPresentation from '@/components/auth/PasswordResetPresentation.vue'
import { cognitoForgotPassword } from '@/assets/modules/cognito'

export default {
  name: 'PasswordReset',
  components: { PasswordResetPresentation },
  metaInfo() {
    return {
      title: this.$t('パスワードリセット'),
    }
  },
  data() {
    return {
      errorCode: '',
    }
  },
  methods: {
    async handleForgotPassword({ username }) {
      const result = await cognitoForgotPassword(username)

      if (result.isError) {
        this.errorCode = result.data.code
        return
      }

      this.$router.push({ name: 'PasswordChange', query: { username } })
    },
  },
}
</script>
