import { render, staticRenderFns } from "./PasswordResetPresentation.vue?vue&type=template&id=0ca1432b&"
import script from "./PasswordResetPresentation.vue?vue&type=script&lang=js&"
export * from "./PasswordResetPresentation.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports